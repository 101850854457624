import React from "react";
import Layout from "../components/shared/layout";
import SEO from "../components/shared/seo";

const PrivacyPolicy = (props) => {
  return (
    <Layout>
      <SEO title="Disclosure" path="/disclosure/" description="Disclosures related to the Hacking the Grow website"/>
      <div className="mt-24 mb-16">
        https://hackingthegrow.com/ is a participant in the amazon services llc associates program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to amazon.com
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
